.blog-container {
    width: 70%;
    margin: 50px auto;
    display: grid;
    grid-template-columns:0.7fr 0.3fr;
    gap: 20px;
}
.single-blog-title{
    margin: 20px 0;
    font-size: 25px;
}
.blog-container-lf{
    /* background-color: antiquewhite; */
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
    align-self: flex-start;
}
.blog-cards img{
    width: 100%;
}
.blog-container-rt{
    /* background-color: rosybrown; */
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.blog-cards h6{
    font-size:  20px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: underline;
    font-weight: 700;
    padding: 10px 0;
    cursor: pointer;
}
.blog-cards p{
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
}

/* blog -category */
.blog-category{
    border: 1px solid #e7e7e7;
    border-radius: 6px;
    box-shadow: 0 12px 10px #eff0f140;
    padding: 50px 30px 40px;
}
.blog-category h4{
    
    font-size: 22px;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 24px;
}

.blog-category ul{
    padding-left: 0;
    list-style: none;
}

.blog-category .category-ul li{
    border: 1px solid #f2f5fa;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 9px 15px;
    text-transform: uppercase;
    color: #282c3f;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    text-decoration: underline;
}

.blog-category ul li:hover{
    color: #ff3f6c;
}



.blog-recent-post p{
    color: #282c3f;
    text-decoration: underline;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    line-height: 1.4;
}



.blog-recent-post p:hover{
    color: #ff3f6c;
}

.blog-category ul li {
    display: flex;
}

.blog-popular-tags ul li{
    /* border */

    border: 1px solid #f2f4f6;
    color: #282c3f;
    
    display: inline-block;
    font-size: 14px;
    line-height: 1;
    padding: 10px 26px;
    text-transform: uppercase;
    transition: all .3s ease-out ;
    cursor: pointer;
}
.blog-popular-tags ul li:hover{
    color: #ff3f6c;
}

.blog-popular-tags ul{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.blog-rec-img-container{
    max-width: 75px;

}

.blog-rec-img-container img{
    width: 100%;
}

.rec-container{
    display: flex;
    gap: 5px;
}

.rec-container:not(:last-child){
    margin-bottom: 10px;
}
.no-blog-found{
    grid-column: span 3;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    font-size: 18px;
    color: #777;
    font-weight: bold;
    width: 100%;
}



@media(max-width :1024px){
   
    .blog-container {
        width: 90%;
        grid-template-columns:1fr 0.3fr;

    }

    .blog-rec-img-container {
        max-width: 75px;
    }

    .blog-container-lf{
        grid-template-columns: 1fr;
    }

}



@media(max-width:768px){

    .blog-container {
        width: 80%;
    }

    .blog-container {
        grid-template-columns: 1fr;
    }
   
    
}


@media(max-width:425px){
    .blog-container {
        width: 90%;
    }
    .blog-container img{
        width:100%;
        height: 300px;
        object-fit: cover

    };
   


}