.section7-bg {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
}

.SubcribeClass {
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #424040;
}

.sub-p {
  font-size: 1rem;
  letter-spacing: 1px;
}

.section7-content .form-control:focus {
  box-shadow: none !important;
  border-color: #ff3f6c !important;
}
.email-input {
  padding: 12px;
  font-size: 1rem;
}

.btn-color-sub {
  background-color: #ff3f6c;
  border: 1px solid #ff3f6c;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  padding: 10px 20px;
  text-decoration: none;
}

.btn-color-sub:hover {
  border: 1px solid #ff3f6c;
  background-color: transparent;
  color: #ff3f6c;
}

@media (max-width: 768px) {
  .SubcribeClass {
    font-size: 1.5rem;
  }
  .sub-p {
    font-size: 0.9rem;
  }
  .email-input {
    font-size: 0.9rem;
    padding: 10px;
  }
  .btn-color-sub {
    font-size: 0.9rem;
    padding: 8px 15px;
  }
}

@media (max-width: 576px) {
  .section7-content {
    text-align: center;
  }
  .email-input {
    width: 100%;
    margin-bottom: 10px;
  }
  .btn-color-sub {
    width: 100%;
  }
}
