@media (min-width: 768px) {
    .m-view {
      display: none !important;
    }
    .d-view {
      display: block !important;
    }
  }
  
  @media (max-width: 767px) {
    .m-view {
      display: block !important;
    }
    .d-view {
      display: none !important;
    }
  }