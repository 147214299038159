.icons {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 15px;
}

.icon {
  font-size: 20px;
  color: #000000;
  cursor: pointer;
  transition: color 0.3s ease;
}

.icon:hover {
  color: #f8037d !important;
}

.icons .icon-btn-wrapper {
  position: relative;
  display: inline-block;
}

.icons .icon {
  color: transparent;
  background-color: var(--icon-bg-color);
}

.icons .icon.filled-icon {
  color: var(--icon-color);
}

.icon-text {
  display: block;
  font-size: 10px;
  margin-top: 5px;
  text-align: center;
  margin-bottom: 0;
}

.cart-count {
  background-color: red !important;
  color: white;
  font-size: 10px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.dropdown_icons {
  position: absolute;
  top: 70%;
  z-index: 9999;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  font-size: 15px;
  font-family: Arial, sans-serif;
}

.dropdown_icons .welcome-text {
  font-size: 14px;
  font-weight: 800;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  color: #333;
  border-bottom: 1px solid #f0f0f0;
}

.dropdown_icons .dropdown-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown_icons .dropdown-list li {
  padding: 10px 15px;
  font-size: 14px;
  color: #555;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown_icons .dropdown-list li:hover {
  background-color: #f9f9f9;
  color: #000;
}

.dropdown_icons .new-badge {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown_icons .new-badge span {
  background-color: #ff3e6c;
  color: #fff;
  padding: 5px;
}

.dropdown_icons .logout {
  color: #ff3e6c;
}

.dropdown_icons .logout:hover {
  background-color: #ffe6e9;
  color: #0e0e0e;
}

.dropdown_icons .login-btn {
  color: #ff3e6c;
  background-color: transparent;
  border: none;
  padding: 5px;
  margin: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  width: calc(100% - 30px);
  font-size: 14px;
}

.dropdown_icons .login-btn:hover {
  background-color: #ffffff;
}

.dropdown-logged-out {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.dropdown-list li {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-size: 14px;
  color: #555;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown-list li:hover {
  background-color: #f9f9f9;
  color: #000;
}

.dropdown-list li i {
  margin-right: 10px; /* Space between icon and text */
  font-size: 16px; /* Adjust icon size */
  color: #ff3e6c; /* Icon color */
}

.dropdown-list li:hover i {
  color: #ff1744; /* Icon hover color */
}
.dropdown-list {
  list-style: none; /* Removes dots */
  padding: 0;
  margin: 0;
}

.li-gap-header {
  margin-bottom: 10px;
}

.search-container {
  display: flex;
  align-items: center;
}

.search-box {
  display: inline-block;
}

.mobile-search-bar {
  width: 100%;
  position: fixed;
  top: 10;
  z-index: 9;
  padding: 8px;
  background-color: white;
}

.mobile-search-box {
  margin-bottom: 10px;
  box-shadow: 0px 4px 6px rgba(56, 55, 55, 0.1);
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  box-sizing: border-box;
}
