.header {
  background-color: white;
  display: flex;
  justify-content: space-between;
  text-align: start;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.Main_Navigation {
  display: flex;
  justify-content: space-evenly;
}

.nav-list {
  list-style: none;
  padding: 0;
  margin: 27px;
  display: flex;
  justify-content: space-between;
  gap: 25px;
  align-items: center;
}

.nav-item {
  position: relative;
  cursor: pointer;
  color: #0d0d0e;
  font-size: small;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
}

.nav-item:hover {
  color: #eb0784;
}

.search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 1rem;
}

.search-box {
  width: 600px;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  transition: box-shadow 0.3s ease;
}

.search-box:focus {
  box-shadow: 0 0 8px #ff3f6c;
  border-color: #ff3f6c;
}

/* Dropdown-specific styling */
.dropdown-css {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  display: none;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 5px;
  max-height: 400px;
  min-width: 800px;

  overflow-y: auto;
  z-index: 9999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Ensure dropdown is visible when parent or dropdown itself is hovered */
.nav-item:hover .dropdown-css,
.dropdown-css:hover {
  display: flex; /* Show dropdown on hover */
}

.dropdown-column {
  padding: 5px;
}

.category-title {
  font-size: 12px;
  color: rgb(241, 81, 108);
  margin-bottom: 10px;
}

.child-list {
  list-style: none;
  padding: 0;
}

.child-item {
  font-size: 10px;
  color: #686565;
  margin-bottom: 1px;
  text-transform: capitalize;
  font-weight: 400 !important;
}

.child-item:hover {
  color: #d10d6f;
  cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .dropdown-menu .username {
    font-size: 16px;
  }

  .icon-btn {
    font-size: 18px;
  }

  .dropdown-menu {
    width: 90%;
  }
}

@media (max-width: 576px) {
  .dropdown-menu .username {
    font-size: 14px;
  }

  .dropdown-menu ul li a {
    font-size: 12px;
  }
}
.login-ptag {
  font-size: 14px;
  margin-bottom: 0;
}
.login-h5tag {
  font-size: 15px;
}
.header-signup-login-btn {
  display: inline-block;
  padding: 10px 15px;
  margin: 10px 10px 0 0;
  border: 1px solid #eaeaec;
  border-radius: 2px;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  color: #ff3f6c;
  font-weight: 700;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 150%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  transition: box-shadow 0.3s ease, visibility 0s linear 0.3s, opacity 0.3s ease;
  width: 200px;
  visibility: hidden;
  opacity: 0;
}

.dropdown-menu .username {
  font-size: 16px;
  margin: 0;
  color: #333;
  text-align: start;
}

.dropdown-menu .username-underline {
  margin: 5px 0;
  width: 100%;
  border: 0;
  border-top: 1px solid #ccc;
}

.dropdown-menu ul {
  list-style: none;
  margin: 0;
  padding: 15px;
  padding-top: 0;
}

.dropdown-menu ul li {
  margin: 5px 0;
  font-size: 20px;
}

.dropdown-menu ul li a {
  text-decoration: none;
  color: #333;
  font-size: 14px;
}

.dropdown-menu ul li a:hover {
  color: #007bff;
}

/* Make the dropdown visible on hover */
.icon-btn-wrapper:hover .dropdown-menu {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 0.3s ease;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .icon-btn {
    font-size: 18px;
    padding: 8px;
  }
  .dropdown-menu {
    width: 250px;
  }
}

@media (max-width: 992px) {
  .dropdown-menu {
    width: 250px;
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .dropdown-menu {
    width: 90%;
    font-size: 14px;
    transform: translateX(-30%);
  }

  .icon-btn-wrapper {
    padding: 0 10px;
  }

  .icon-btn {
    font-size: 16px;
    padding: 8px;
  }

  .dropdown-menu ul li {
    font-size: 16px;
  }

  .dropdown-menu .username {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .dropdown-menu {
    width: 90%;
    font-size: 12px;
    transform: translateX(-50%);
  }

  .icon-btn-wrapper {
    display: inline-block;
    margin-right: 10px;
  }

  .icon-btn {
    font-size: 16px;
  }

  .dropdown-menu ul li {
    font-size: 14px;
  }

  .dropdown-menu .username {
    font-size: 12px;
  }
}
